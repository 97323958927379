import { GET_API, POST_API, PUT_API } from '../configApi'

export const getDeviceCode = async (code: string): Promise<any> => {
  return await GET_API({ endpoint: `admin/api/device/search?device_code=${code}` })
}

export const createDeviceCode = async (data: any): Promise<any> => {
  return await POST_API({ endpoint: `admin/api/device/create`, data })
}

export const updateDeviceCode = async (data: any): Promise<any> => {
  return await PUT_API({ endpoint: `admin/api/device/update`, data })
}

export const getLocation = async (): Promise<any> => {
  // return await GET_API({ endpoint: `admin/com_api/api/locations/paging?page=1&limit=500&status=active` })
  return await GET_API({ endpoint: `admin/com_api/api/locations/assigned?type=holdstock` })                            
}