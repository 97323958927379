import { getLocation, updateDeviceCode } from '@/src/apis/'
import { Footer, Header } from '@/src/components'
import { APP_INFO } from '@/src/constants'
import { useApp } from '@/src/context'
import { IAppInfoLocalStorage } from '@/src/types'
import { showToast, useLocalStorage } from '@/src/utils'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

const ENVIRONTMENT = import.meta.env.VITE_ENVIRONMENT as string

const Location: React.FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const isInitialMount = useRef(true)
  const [location, setLocation] = useState<any>(null)
  const [appInfo, setAppInfo] = useLocalStorage<IAppInfoLocalStorage | null>(APP_INFO, null)
  const { onLoading, setIsModalPIN } = useApp()

  useEffect(() => {
    // PREVENT CALL API 2 TIMES IN STRICTMODE
    if (isInitialMount.current) {
      onLoadData()
      isInitialMount.current = false
    }
  }, [])

  const onBack = async () => {
    await setIsModalPIN(true)
    navigate('/home')
  }

  const onLoadData = async () => {
    onLoading(true)
    const res = await getLocation()
    if (!res?.is_error) {
      if (ENVIRONTMENT === 'staging') {
        setLocation(res?.data?.data)
      } else {
        const filteredData = res?.data?.data?.filter((item: any) => item.name.toLowerCase().includes('urban'))
        setLocation(filteredData)
      }
    }
    onLoading(false)
  }

  const onClickLocation = async (val: any) => {
    setAppInfo({
      ...appInfo,
      location: val
    } as IAppInfoLocalStorage)

    const responseUpdate = await updateDeviceCode({
      device_code: appInfo?.deviceCode,
      location_id: val.id
    })

    if (responseUpdate?.is_error) return
    showToast('success', t('toast.locationUpdated'))

    //  navigate(-1)
  }

  return (
    <div className='layout'>
      <Header title='settings.locations' onBack={onBack} />

      <div className='flex flex-col w-full gap-6 items-center	mt-10'>
        {location?.map((val: any, index: any) => {
          const isSelected = appInfo?.location?.id === val?.id

          const { countryName, provinceName, districtName, wardName, address, name, phone, email } = val

          const parts = [countryName, provinceName, districtName, wardName, address, name, phone, email].filter(
            (part) => part
          ) // Chỉ giữ lại các phần có giá trị

          return (
            <button
              onClick={() => onClickLocation(val)}
              className='w-7/12 flex flex-row	justify-between btn-custom bg-blue-50 px-4 py-4 rounded-md items-center'
              key={index}
            >
              <div className='w-4/5 flex flex-col gap-4 items-start'>
                <p className='text-start font-bold responsive-text-small-2'>{val?.name}</p>
                <p className='text-start responsive-text-small-2'>{parts.join(' - ')}</p>
              </div>
              <div className='flex flex-1 justify-end'>
                <div className='flex items-center justify-center w-8 h-8 bg-white rounded-full border  border-black '>
                  {isSelected && <div className='w-4 h-4  bg-blue-500 rounded-full'></div>}
                </div>
              </div>
            </button>
          )
        })}
      </div>
      <Footer />
    </div>
  )
}

export default Location
